<template>
  <div id="offers" class="py-18 lg:py-20 bg-np-fog">
    <div
      data-aos="fade-up"
      class="px-4 lg:px-8 max-w-screen-lg mx-auto space-y-6"
    >
      <div>
        <p class="header-special-offer text-left text-np-3xl">other rooms</p>
      </div>

      <div
        class="flex flex-row overflow-x-scroll lg:container lg:mx-auto lg:grid lg:grid-cols-4 gap-6"
      >
        <router-link
          class="flex-none w-64 lg:w-auto lg:col-span-1 lg:flex lg:flex-col text-left"
          v-for="(item, index) in currentItems"
          :to="`/${type}/${item.title}`"
          :key="index"
        >
          <div
            class="relative w-full aspect-4-3-landscape rounded-tr-neera overflow-hidden mb-4"
          >
            <img
              class="absolute object-cover w-full h-full"
              :src="item.image_url"
            />
          </div>
          <!-- <p
            class="flex flex-wrap font-light text-np-xl saoltext text-np-green"
            v-html="`${item.title} &ndash; ${item.type}`"
          ></p> -->
          <div class="flex flex-wrap space-y-2 text-xs">
            <p class="font-light text-np-xl saoltext text-np-green">
              {{ `${item.title} &ndash; ${item.type}` }}
            </p>
            <p
              class="description-special-offer text-np-green text-np-card font-light"
            >
              {{ item.description }}
            </p>
            <neera-anchor
              :linkTo="`/${type}/${item.title}`"
              class="text-np-base"
            >
              discover more
            </neera-anchor>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import NeeraAnchor from "./NeeraAnchor.vue";

export default {
  name: "OtherRooms",
  components: {
    NeeraAnchor,
  },
  props: {
    titleList: {
      type: [String],
      default: "",
    },
    type: {
      type: [String],
      default: "rooms",
    },
    excludePath: {
      type: [String],
      default: "",
    },
  },
  async mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
  },
  data() {
    return {
      lang: "en",
      rooms: [
        {
          title: "well",
          type: "studio",
          link: "studio",
          image_url: require("@/assets/rooms/Rooms_02-01well.png"),
          description:
            "live ‘well’, in our compact yet spacious studio room with 2 single beds",
        },
        {
          title: "pond",
          type: "deluxe room",
          link: "deluxe",
          image_url: require("@/assets/rooms/Rooms_02-02pond.png"),
          description:
            "perfect for a mindful getaway with a comfortable king-size bed",
        },
        {
          title: "mindful",
          type: "signature",
          link: "signature-deluxe",
          image_url: require("@/assets/rooms/Rooms_02-03mindful.png"),
          description:
            "our signature room with special mindful amenities, river view with a king-size bed",
        },
        {
          title: "lagoon",
          type: "family suite",
          link: "family-suite",
          image_url: require("@/assets/rooms/Room_AllRooms_05-lagoon.png"),
          description:
            "perfect for families or a group of 3–4 friends, with 2 double queen-size beds",
        },
        {
          title: "lake",
          type: "panoramic suite",
          link: "paranomic-suite",
          image_url: require("@/assets/rooms/Room_AllRooms_06-lake.png"),
          description:
            "breathtaking panoramic sights of the Tha Chin river with a king-size bed",
        },
      ],
    };
  },
  computed: {
    currentItems() {
      let arr = [];
      for (const element of this.rooms) {
        let checkpath = "/rooms/" + element.title;
        if (checkpath != this.excludePath) arr.push(element);
      }
      return arr;
    },
  },
};
</script>

<style scoped>
.description-special-offer {
  font-style: normal;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 60px;
}

.card-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.pin {
  position: absolute;
  top: 0;
}

.discover-more {
  font-family: "Saol Text" !important;
  background: #5c623f;
  color: white;
}

.card-height {
  position: relative;
  padding-top: 140%;
}

.title {
  font-family: "Saol Text" !important;
  color: #5c623f;
}

.badge {
  color: #994e2e;
}

.booking {
  background: rgba(206, 205, 200, 1);
}

input {
  background: transparent;
  border: none;
  position: relative;
  display: block;
  outline: none;
  width: 10vw;
  height: 42px;
  padding: 10px;
  color: #333;
  border: 0;
  outline: 0;
  background: transparent;
}
::-webkit-input-placeholder {
  color: #333 !important;
}
:-moz-placeholder {
  color: #333 !important;
}
::-moz-placeholder {
  color: #333 !important;
}
:-ms-input-placeholder {
  color: #333 !important;
}

button {
  border-radius: 10px;
  height: 42px;
  border: 1px solid;
  border-image-source: linear-gradient(0deg, #050505, #050505),
    linear-gradient(0deg, #2f342b, #2f342b);
}

.border-bottom-dash {
  border-bottom: 1px solid black;
  border-style: dashed;
}

.text-litepie-secondary-700,
.placeholder-litepie-secondary-400,
.text-litepie-secondary-100,
.placeholder-litepie-secondary-500 {
  color: #666;
}

.subtitle {
  font-style: normal;
  font-weight: 300;
}

.header2 {
  font-weight: 300;
}

.header {
  width: 100%;
  height: 725px;
}

.img-para {
  width: 459px;
  height: 652px;
}

.title-para {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.header-special-offer {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.title-special-offer {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.description-special-offer {
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.content-para {
  font-family: "Roboto";
  color: #5c623f;
}

.padding {
  padding-left: 10%;
  padding-right: 10%;
}

.special-offer {
  background: #e7e5dc;
}

.gallery-label {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #f0efea;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img {
  border-radius: 0px 40px 0px 0px;
}
</style>

<template>
  <div
    class="max-w-screen-lg mx-auto grid grid-cols-1 lg:grid-cols-2 px-4 lg:px-8 mt-0 lg:mt-8 mb-18 lg:mb-20 gap-y-8 lg:gap-x-16"
  >
    <div class="relative w-full aspect-4-3 rounded-tr-np-lg overflow-hidden">
      <img class="absolute object-cover w-full h-full" :src="imageUrl" />
    </div>
    <div class="items-center text-left space-y-8 lg:space-y-10">
      <div class="space-y-2">
        <p
          id="season-restaurant"
          class="title text-np-64 lg:text-np-3xl neera-text-glimps saoltext"
        >
          {{ title }}
        </p>
        <p class="text-np-glimpse font-light text-np-xl">{{ type }}</p>
      </div>
      <p class="neera-text-green text-np-base" v-html="description"></p>
      <highlight-item :size="size" :unit="unit" :highlights="highlights" />
      <neera-outline-button linkTo="/" text="book now" />
    </div>
  </div>
</template>
<script>
import NeeraOutlineButton from "@/components/NeeraOutlineButton.vue";
import HighlightItem from "./HighlightItem.vue";

export default {
  name: "RoomDetail",
  components: {
    NeeraOutlineButton,
    HighlightItem,
  },
  props: {
    imageUrl: {
      type: [String],
    },
    title: {
      type: [String],
    },
    type: {
      type: [String],
    },
    description: {
      type: [String],
    },
    size: {
      type: [String],
    },
    unit: {
      type: [String],
    },
    highlights: {
      type: Array,
    },
  },
};
</script>

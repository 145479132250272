<template>
  <div
    class="max-w-screen-lg mx-auto px-4 lg:px-8 grid grid-cols-1 lg:grid-cols-2 my-18 lg:my-20 gap-y-10 lg:gap-x-16"
  >
    <div class="space-y-8">
      <p class="saoltext text-np-56 lg:text-np-3xl neera-text-glimps text-left">
        room facilities
      </p>
      <div class="grid grid-cols-2 lg:grid-cols-1 gap-y-4">
        <div
          class="flex flex-row h-12 md:h-8 w-auto"
          v-for="(facility, index) in facilities"
          :key="index"
        >
          <div
            class="flex-none h-full w-12 flex items-center justify-center text-right"
          >
            <img class="icon" :src="facility.iconUrl" />
          </div>
          <div
            class="flex text-left neera-text-green text-np-sm-label md:text-np-base pl-6 items-center"
          >
            <p v-html="facility.name"></p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <img class="mx-auto" :src="imageUrl" />
    </div>
  </div>
</template>
<script>
export default {
  name: "RoomDetail",
  props: {
    facilities: {
      type: Array,
    },
    imageUrl: {
      type: [String],
    },
  },
};
</script>
